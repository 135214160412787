<template>
  <div class="page">
    <header-layout></header-layout>
    <div class="page-main">
      <div class="leftMenu">
        <sidebar-layout></sidebar-layout>
      </div>
      <div class="right">
        <router-view class="main-body" />
      </div>
    </div>
  </div>
</template>

<script>
// ***插件架构区***

// ***方法区***

// ***接口区***

// ***公共组件区***
import headerLayout from "./headerLayout.vue";
import sidebarLayout from "./sidebarLayout.vue";
export default {
  components: { headerLayout, sidebarLayout },
  props: {},
  setup() {},
  onMounted() {},
};
</script>

<style lang="scss" scoped>
.page {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  .page-main {
    padding-top: 60px;
    height: 100%;
    .right {
      overflow: auto;
      margin-left: 200px;
      height: 100%;
      background-color: #ebeef5;
      padding: 20px;
    //   min-width: fit-content;
      color: #666666;
      .main-body {
        background-color: #ffffff;
        padding: 20px;
      }
    }
  }
}
</style>
