function getInput(el) {
  let inputEle;
  const { tagName } = el;
  if (tagName === "INPUT" || tagName === "TEXTAREA") {
    inputEle = el;
  } else {
    inputEle = el.querySelector("input");
    if (!inputEle) {
      inputEle = el.querySelector("textarea");
    }
  }
  return inputEle;
}

function dispatchEvent(el, type) {
  let evt = document.createEvent("HTMLEvents");
  evt.initEvent(type, true, true);
  el.dispatchEvent(evt);
}

const Trim = {
  mounted: el => {
    if (!el) return;
    let inputEle = getInput(el);
    const handler = function (event) {
      const newVal = event.target.value.trim();
      if (event.target.value != newVal) {
        event.target.value = newVal;
        dispatchEvent(inputEle, "input");
      }
    };
    el.inputEle = inputEle;
    el._blurHandler = handler;
    inputEle?.addEventListener("blur", handler);
  },

  beforeUnmount(el) {
    const { inputEle } = el;
    inputEle?.removeEventListener("blur", el._blurHandler);
  },
};

// Trim.install = app => {
//   app.directive("trim", Trim);
// };

export default Trim;