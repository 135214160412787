<template>
  <div class="header">
    <div class="logo">
      <img src="@/assets/logo_white.png" class="image" />
      <!-- <span class="text">运营端</span> -->
    </div>
    <div class="right">
      <el-dropdown class="userContent">
        <span class="el-dropdown-link">
          {{ userInfo.name }}<el-icon><CaretBottom /></el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item :icon="Plus" @click="logout"
              >退出</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
// ***插件架构区***
import {
  onMounted,
  reactive,
  toRefs,
  ref,
  computed,
  getCurrentInstance,
} from "vue";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
// ***方法区***
import { removeToken } from "@/utils/auth";
// ***接口区***

// ***公共组件区***
import { CaretBottom } from "@element-plus/icons-vue";
export default {
  components: { CaretBottom },
  props: {},
  setup() {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    const router = useRouter();
    onMounted(() => {
      console.log(userInfo);
    });
    const logout = () => {
      removeToken();
      router.push(`/login`);
    };
    return {
      userInfo,
      logout,
    };
  },
  onMounted() {},
};
</script>

<style lang="scss" scoped>
.header {
  height: 60px;
  color: #fff;
  background: rgb(64, 158, 255);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  box-sizing: border-box;
  .logo {
    width: 200px;
    // border-right: 1px solid #c0c4cc;
    // margin-left: -20px;
    text-align: center;
    font-size: 25px;
    cursor: pointer;
    box-sizing: border-box;
    .image {
      width: 160px;
      height: 35px;
      vertical-align: middle;
      margin: 0 auto;
    }

    .text {
      color: #fff;
      margin-left: 10px;
    }
  }
  .right {
    padding-right: 50px;
    .el-dropdown-link {
      color: #fff;
      display: flex;
      align-items: center;
      cursor: default;
    }
  }
}
</style>
