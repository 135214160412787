/*
 * @Author: wayne
 * @Date: 2024-06-06 09:12:17
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-06-06 17:43:26
 * @Description: 请填写简介
 */
import Layout from '@/layout'
export default [
	{
		path: '/',
		menu: true,
		name: 'layout',
		component:Layout,
		redirect:'/information',
		children: [
		
			{
				path: 'information',
				name: 'information',
				component: () => import('@/views/informationManage/index.vue'),
				meta: {
					menu: true,
					title:'留言管理',
				}
			},
			{
				path: 'news',
				name: 'news',
				component: () => import('@/views/newsManage/index.vue'),
				meta: {
					menu: true,
					title:'新闻管理',
				}
			},
		]
	}
]
