<template>
  <div class="sidebarLayout">
    <el-menu
      :default-active="selectMenu"
      class="el-menu-vertical-demo"
      :router="true"
    >
      <template v-for="item in getMenuList">
        <el-menu-item
          v-if="!item.children && item.meta.menu"
          :index="item.name"
        >
          <span>{{ item.meta.title }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
// ***插件架构区***
import {
  onMounted,
  reactive,
  toRefs,
  ref,
  computed,
  getCurrentInstance,
} from "vue";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";
import routers from "@/router/routes.js";

// ***方法区***
import { removeToken } from "@/utils/auth";
// ***接口区***

// ***公共组件区***

export default {
  components: {},
  props: {},
  setup() {
    //   onBeforeRouteUpdate((to, from) => {
    //   state.selectMenu = to.name;
    // });
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    const router = useRouter();
    const route = useRoute();
    console.log(route.path);
    const state = reactive({
		//   selectMenu: route.name,
    });
    const getMenuList = computed(() => {
      let routerList = routers[0].children;
      routerList = routerList.filter((item) => item.meta.menu);
      return routerList;
    });

    const selectMenu = computed(() => {
      return route.name;
    });

    return {
      ...toRefs(state),
      getMenuList,
      selectMenu,
    };
  },
  onMounted() {},
};
</script>

<style lang="scss" scoped>
.sidebarLayout {
  width: 200px;
  height: 100%;
  position: fixed;
  font-size: 0px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden;
  padding-top: 60px;
  border-right: 1px solid #e6e6e6;
  .el-menu {
    border-right: none;
  }
}
</style>
