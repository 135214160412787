/*
 * @Author: wayne
 * @Date: 2024-06-05 14:11:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-06-05 14:12:46
 * @Description: 请填写简介
 */

import trim from "./trim"; 

const directiveList = {
	trim
}

const directives = {
	install: function (app) {
		Object.keys(directiveList).forEach((key) => {
			app.directive(key, directiveList[key]); // 注册
		});
	}
}
export default directives;

